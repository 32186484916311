.markdown-container {
    padding: 2rem 0;
    min-height: 100vh;
    background: #ffffff;
}

.markdown-content {
    font-family: Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
    line-height: 1.6;
    font-size: 1rem;
    color: #333;
    max-width: 680px;
    margin: 0 auto;
}

.markdown-content h1 {
    font-size: 2rem;
    margin: 1.5rem 0;
    font-weight: 600;
    color: #333;
}

.markdown-content h2 {
    font-size: 1.5rem;
    margin: 1.5rem 0 1rem;
    font-weight: 600;
}

.markdown-content h3 {
    font-size: 1.25rem;
    margin: 1.25rem 0 0.75rem;
    font-weight: 600;
}

.markdown-content p {
    margin: 1rem 0;
    color: #666;
}

.markdown-content a {
    color: #333;
    text-decoration: none;
    border-bottom: 1px solid transparent;
    transition: border-color 0.2s ease;
}

.markdown-content a:hover {
    border-bottom-color: #333;
}

.markdown-content ul, 
.markdown-content ol {
    margin: 1rem 0;
    padding-left: 2rem;
}

.markdown-content li {
    margin: 0.5rem 0;
}

.markdown-content blockquote {
    margin: 1.5rem 0;
    padding: 0.5rem 0 0.5rem 1.5rem;
    border-left: 4px solid #e5e7eb;
    color: #4a5568;
    font-style: italic;
}

.markdown-image {
    margin: 2rem 0;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.markdown-image img {
    display: block;
    margin: 0 auto;
}

.markdown-content code {
    background: #f5f5f5;
    padding: 0.2em 0.4em;
    border-radius: 4px;
    font-size: 0.9em;
    color: #333;
}

.markdown-content pre {
    background: #f5f5f5;
    padding: 1rem;
    border-radius: 8px;
    overflow-x: auto;
    margin: 1.25rem 0;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.markdown-content pre code {
    background: none;
    padding: 0;
}

.back-button {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    color: #333;
    text-decoration: none;
    margin-bottom: 2rem;
    padding: 0.5rem 1rem;
    border-radius: 8px;
    transition: all 0.2s ease;
    background: #ffffff;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.back-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.15);
}

@media (max-width: 768px) {
    .markdown-content {
        font-size: 0.95rem;
        padding: 0 1rem;
    }

    .markdown-content h1 {
        font-size: 1.75rem;
    }

    .markdown-content h2 {
        font-size: 1.5rem;
    }

    .markdown-content h3 {
        font-size: 1.25rem;
    }
}

.daily-work-icon {
    width: 24px;
    height: 24px;
    background-image: url('https://everyday-gif-bucket.s3.amazonaws.com/gifs/Movie%20on%2021-12-2024%20at%2017.47_100x100.gif?AWSAccessKeyId=AKIAVOC56XXKGLT45CUM&Signature=KjjQ7AgMyXCfhaSSecC6qHlSAWY%3D&Expires=1736963844');
    background-size: contain;
    background-repeat: no-repeat;
    display: inline-block;
    vertical-align: middle;
}