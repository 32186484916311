.focus-pet-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
}

.focus-pet {
    image-rendering: pixelated;
    transition: all 0.3s ease;
}

.pet-body {
    position: relative;
    width: 32px;
    height: 32px;
    transform-origin: bottom center;
    animation: idle 2s ease-in-out infinite;
}

/* Size variations */
.size-1 { transform: scale(1); }
.size-2 { transform: scale(1.5); }
.size-3 { transform: scale(2); }

/* Mood animations */
.mood-hungry .pet-body {
    animation: hungry 1s ease-in-out infinite;
    opacity: 0.7;
}

.mood-happy .pet-body {
    animation: happy 0.5s ease-in-out infinite;
}

.brain-top {
    width: 24px;
    height: 16px;
    background: #ff69b4;
    border-radius: 12px 12px 0 0;
    position: absolute;
    top: 0;
    left: 4px;
}

.brain-bottom {
    width: 28px;
    height: 20px;
    background: #ff69b4;
    border-radius: 14px;
    position: absolute;
    bottom: 0;
    left: 2px;
}

.sparkle {
    position: absolute;
    width: 8px;
    height: 8px;
    background: yellow;
    clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
    animation: sparkle 1s ease-in-out infinite;
}

.pet-status {
    text-align: center;
    font-size: 12px;
    margin-top: 8px;
    opacity: 0;
    transition: opacity 0.3s;
}

.focus-pet:hover .pet-status {
    opacity: 1;
}

@keyframes idle {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-2px); }
}

@keyframes hungry {
    0%, 100% { transform: scale(1); }
    50% { transform: scale(0.95); }
}

@keyframes happy {
    0%, 100% { transform: rotate(-5deg); }
    50% { transform: rotate(5deg); }
}

@keyframes sparkle {
    0%, 100% { opacity: 0; transform: scale(0.8); }
    50% { opacity: 1; transform: scale(1); }
} 