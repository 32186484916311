.yearly-interview {
  min-height: 100vh;
  height: 100vh;
  background: #FFFFFF;
  padding: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, sans-serif;
  overflow: hidden;
}

.interview-container {
  max-width: 1100px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  gap: 2.5rem;
  align-items: center;
}

.left-section {
  padding-right: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  height: 100%;
  max-height: 600px;
}

.interview-header {
  text-align: left;
}

.interview-header h1 {
  font-size: 4.5rem;
  color: #000000;
  margin-bottom: 0.75rem;
  font-weight: 900;
  letter-spacing: -0.03em;
  line-height: 1;
}

.subtitle {
  color: #555;
  font-size: 1rem;
  margin: 0;
  line-height: 1.5;
  font-weight: 400;
  max-width: 400px;
}

/* Improved Question Section */
.question-status {
  text-align: left;
  max-width: 400px;
  background: rgba(255, 255, 255, 0.95);
  padding: 1rem;
  border: 2px solid #000;
  box-shadow: 8px 8px 0 rgba(0,0,0,0.1);
  /* margin-top: auto; */
}

.progress {
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.question-counter {
  font-size: 0.75rem;
  color: #555;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-weight: 500;
}

.progress-bar {
  flex-grow: 1;
  height: 2px;
  background: rgba(0, 0, 0, 0.1);
  position: relative;
}

.progress-fill {
  height: 100%;
  background: #000000;
  transition: width 0.3s ease;
}

.current-question {
  font-size: 1.5rem;
  color: #000000;
  line-height: 1.3;
  margin: 1rem 0 0;
  font-weight: 600;
  position: relative;
  /* padding-left: 1rem; */
}

/* .current-question::before {
  content: "→";
  position: absolute;
  left: -1rem;
  color: #000;
} */

/* Enhanced Video Section */
.right-section {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.canvas-container {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  /* border: 3px solid #000; */
  /* box-shadow: 12px 12px 0 rgba(0,0,0,0.1); */
  background: #fff;
}

.canvas-container canvas {
  width: 100% !important;
  height: auto !important;
  max-width: 600px;
  display: block;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Floating Question Overlay */
.canvas-container::after {
  content: attr(data-current-question);
  position: absolute;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  max-width: 560px;
  background: rgba(0,0,0,0.9);
  color: #fff;
  padding: 1.5rem;
  font-size: 1.25rem;
  font-weight: 500;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
  z-index: 2;
}

.show-question .canvas-container::after {
  opacity: 1;
}

/* Controls Enhancements */
.controls {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  /* margin-top: auto; */
}

.button-group {
  display: flex;
  gap: 0.75rem;
}

button {
  width: 100%;
  padding: 1rem 1.5rem;
  border-radius: 0;
  font-size: 0.875rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  border: 2px solid #000;
}

.primary-button {
  background: #000000;
  color: #FFFFFF;
}

.primary-button:hover {
  background: #333;
  transform: translateY(-2px);
}

.secondary-button {
  background: transparent;
  color: #000000;
}

.secondary-button:hover {
  background: rgba(0, 0, 0, 0.05);
}

@media (max-width: 1024px) {
  /* .interview-container {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  } */

  .canvas-container::after {
    bottom: 1rem;
    font-size: 1.1rem;
    padding: 1rem;
  }
}