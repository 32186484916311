.idea-dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 20px;
}

.idea-dialog {
  background: white;
  padding: 35px;
  border-radius: 12px;
  max-width: 600px;
  width: 90%;
  max-height: 90vh;
  overflow-y: auto;
  font-family: 'Comic Sans MS', 'Chalkboard SE', 'Marker Felt-Thin', sans-serif;
}

.idea-dialog h2 {
  text-align: left;
  margin-bottom: 35px;
  font-size: 2rem;
  color: #333;
}

.idea-dialog .steps {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.idea-dialog .step {
  display: flex;
  gap: 20px;
  padding: 25px;
  background: #f8f8f8;
  border-radius: 10px;
  align-items: flex-start;
}

.idea-dialog .step-number {
  background: #ffd700;
  width: 40px;
  height: 40px;
  min-width: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 1.4rem;
  margin-top: 3px;
}

.idea-dialog .step-info {
  flex: 1;
}

.idea-dialog .step-info h3 {
  margin: 0 0 12px 0;
  font-size: 1.4rem;
  color: #333;
  text-align: left;
}

.idea-dialog .step-info p {
  margin: 0;
  color: #444;
  line-height: 1.6;
  font-size: 1.15rem;
  text-align: left;
}

.idea-dialog .upload-link {
  display: block;
  margin-top: 12px;
  color: #333;
  text-decoration: underline;
  font-size: 1.15rem;
}

.idea-dialog .upload-link:hover {
  color: #000;
}

.idea-dialog .beat-player {
  margin-top: 15px;
  width: 100%;
}

.idea-dialog .dialog-buttons {
  display: flex;
  gap: 20px;
  justify-content: flex-start;
  margin-top: 35px;
}

.idea-dialog .dialog-buttons button {
  padding: 12px 30px;
  font-size: 1.2rem;
  background: #ffd700;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-family: inherit;
}

.idea-dialog .dialog-buttons button:hover {
  background: #ffcf00;
}

/* Простой скроллбар */
.idea-dialog::-webkit-scrollbar {
  width: 8px;
}

.idea-dialog::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.idea-dialog::-webkit-scrollbar-thumb {
  background: #ddd;
  border-radius: 4px;
}

/* Анимация появления */
@keyframes dialogAppear {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.idea-dialog {
  animation: dialogAppear 0.3s ease-out;
}

.idea-dialog .footer {
  margin-top: 40px;
  padding-top: 20px;
  border-top: 1px solid #eee;
  text-align: center;
}

.idea-dialog .footer .idea-button {
  margin-bottom: 15px;
  display: inline-block;
}

.idea-dialog .toggle-poem:disabled {
  background: #f0f0f0;
  cursor: not-allowed;
  color: #666;
  opacity: 0.5;
  transition: opacity 0.3s;
}

.idea-dialog .toggle-poem:disabled:hover {
  background: #f0f0f0;
} 