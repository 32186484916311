/* Clean Reset */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    background-color: #fff;
    color: #333;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', sans-serif;
    line-height: 1.6;
    -webkit-font-smoothing: antialiased;
}

.page-wrapper {
    max-width: 640px;
    margin: 0 auto;
    padding: 0 20px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

/* Hero Section */
.hero-section {
    padding: 80px 0 40px;
    text-align: center;
}

.logo-animated {
    width: 160px;
    height: auto;
    transition: transform 0.2s ease;
}

.logo-animated:hover {
    transform: rotate(5deg);
}

.hero-text {
    font-size: 1.1rem;
    color: #666;
    margin-bottom: 1.5rem;
}

/* Content Sections */
.content-section {
    flex: 1;
}

.section-title {
    font-size: 0.875rem;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    color: #666;
    margin-bottom: 2rem;
    font-weight: 500;
}

/* Projects Grid */
.projects-grid {
    display: grid;
    gap: 1.5rem;
    margin-bottom: 4rem;
}

.project-card {
    padding: 1.5rem;
    border: 1px solid #eee;
    border-radius: 8px;
    text-decoration: none;
    color: inherit;
    transition: all 0.2s ease;
}

.project-card:hover {
    border-color: #000;
}

.project-card.featured {
    background: #f8f8f8;
    border: 1px solid #ddd;
}

.project-card h3 {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
    color: #000;
}

.project-card p {
    font-size: 0.9375rem;
    line-height: 1.5;
    color: #666;
    text-align: left;
}

.project-card.featured h3 {
    font-size: 1.375rem;
    color: #000;
}

/* Blog Section */
.section-blog {
    border-top: 1px solid #eee;
    padding-top: 3rem;
    margin-top: 2rem;
}

/* Footer */
.footer {
    padding: 3rem 0;
    text-align: center;
}

.social-links {
    font-size: 0.875rem;
    font-weight: 400;
}

.social-link {
    color: #666;
    text-decoration: none;
    padding: 4px 8px;
    transition: all 0.2s ease;
}

.social-link:hover {
    color: #000;
    background: #f0f0f0;
    border-radius: 4px;
}

.dot {
    color: #ccc;
    margin: 0 4px;
}

/* Responsive */
@media (max-width: 480px) {
    .hero-section {
        padding: 60px 0 30px;
    }
    
    .projects-grid {
        gap: 1rem;
    }
    
    .project-card {
        padding: 1rem;
    }
}

/* Animation */
@keyframes fadeIn {
    from { opacity: 0; transform: translateY(10px); }
    to { opacity: 1; transform: translateY(0); }
}

.content-section > * {
    animation: fadeIn 0.4s ease-out forwards;
}

/* Blog Posts (assuming Timeline component styling) */
.timeline-post-title {
    font-size: 1.25rem;
    font-weight: 600;
    color: #000;
    margin-bottom: 0.5rem;
}

.timeline-post-date {
    font-size: 0.875rem;
    color: #666;
    margin-bottom: 0.25rem;
}

.timeline-post-excerpt {
    font-size: 0.9375rem;
    line-height: 1.5;
    color: #666;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .logo-animated {
        width: 120px;
    }
    
    .project-card h3 {
        font-size: 1.125rem;
    }
    
    .project-card.featured h3 {
        font-size: 1.25rem;
    }
}

.card-content {
    text-align: left;
}

.card-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.card-header h3 {
    margin: 0;
}

.post-icon {
    width: 48px;
    height: 48px;
    min-width: 48px;
    border-radius: 4px;
    overflow: hidden;
    margin-left: 1rem;
}

.post-icon img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* Remove the old preview image styles */
.preview-image-container,
.preview-image {
    display: none;
}


