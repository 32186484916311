.incident-container {
  width: 100vw;
  height: 100%;
}

@keyframes drive-in {
  0% {
    transform: translateX(-200%) scale(0.5);
    opacity: 0;
  }
  100% {
    transform: translateX(0) scale(1);
    opacity: 1;
  }
}

.animate-drive-in {
  animation: drive-in 0.8s ease-out forwards;
}

@keyframes confetti-fall {
  0% {
    transform: translateY(-100vh) rotate(0deg);
  }
  100% {
    transform: translateY(100vh) rotate(720deg);
  }
}

.confetti-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 60;
}

.confetti {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #f0f;
  animation: confetti-fall 4s linear infinite;
}

.confetti:nth-child(2n) {
  background-color: #0ff;
}

.confetti:nth-child(3n) {
  background-color: #ff0;
}

.confetti:nth-child(4n) {
  background-color: #f00;
}

.celebration-content {
  z-index: 70;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
