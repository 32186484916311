.saved-search {
  min-height: 100vh;
  height: 100vh;
  background: #FFFFFF;
  padding: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, sans-serif;
  overflow: hidden;
}

.search-container {
  max-width: 1100px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr;
  gap: 2.5rem;
  align-items: center;
}

.left-section {
  padding-right: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  height: 100%;
  max-height: 600px;
}

.search-header h1 {
  font-size: 4.5rem;
  color: #000000;
  margin-bottom: 0.75rem;
  font-weight: 900;
  letter-spacing: -0.03em;
  line-height: 1;
}

.subtitle {
  color: #555;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 400;
  max-width: 400px;
}

.search-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.search-input {
  width: 100%;
  padding: 1rem;
  font-size: 1rem;
  border: 2px solid #000;
  background: #fff;
  outline: none;
}

.search-input:focus {
  border-color: #000;
  box-shadow: 8px 8px 0 rgba(0,0,0,0.1);
}

.primary-button {
  width: 100%;
  padding: 1rem 1.5rem;
  border: 2px solid #000;
  background: #000;
  color: #fff;
  font-size: 0.875rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  cursor: pointer;
  transition: all 0.2s ease;
}

.primary-button:hover {
  background: #333;
  transform: translateY(-2px);
}

.primary-button:disabled {
  background: #ccc;
  border-color: #ccc;
  cursor: not-allowed;
  transform: none;
}

.error-message {
  padding: 1rem;
  background: rgba(255, 0, 0, 0.1);
  border: 2px solid #ff0000;
  color: #ff0000;
}

.search-result {
  background: rgba(255, 255, 255, 0.95);
  padding: 1rem;
  border: 2px solid #000;
  box-shadow: 8px 8px 0 rgba(0,0,0,0.1);
}

.result-explanation h3,
.result-query h3 {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.ebay-link {
  display: inline-block;
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background: #000;
  color: #fff;
  text-decoration: none;
  font-weight: 500;
  transition: all 0.2s ease;
}

.ebay-link:hover {
  background: #333;
  transform: translateY(-2px);
}

@media (max-width: 1024px) {
  .search-header h1 {
    font-size: 3rem;
  }
  
  .search-container {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
}

.brand-exclusions {
  margin: 20px 0;
}

.brand-checkboxes {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}

.brand-checkbox {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 5px 10px;
  background-color: #f5f5f5;
  border-radius: 15px;
  cursor: pointer;
}

.brand-checkbox input[type="checkbox"] {
  appearance: none;
  width: 16px;
  height: 16px;
  border: 2px solid #666;
  border-radius: 50%;
  margin: 0;
  cursor: pointer;
}

.brand-checkbox input[type="checkbox"]:checked {
  background-color: #666;
  position: relative;
}

.brand-checkbox input[type="checkbox"]:checked::after {
  content: "";
  position: absolute;
  width: 6px;
  height: 6px;
  background-color: white;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
} 