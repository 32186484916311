/* Overall birthday gift page styling */
.birthday-gift-page {
  background: 
    radial-gradient(circle at 20% 20%, rgba(255, 192, 203, 0.2) 0%, transparent 50%),
    radial-gradient(circle at 80% 80%, rgba(255, 215, 0, 0.2) 0%, transparent 50%),
    repeating-linear-gradient(45deg, 
      rgba(255,215,0,0.1) 0px, 
      rgba(255,215,0,0.1) 10px,
      rgba(255,192,203,0.1) 10px, 
      rgba(255,192,203,0.1) 20px
    );
  min-height: 100vh;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Comic Sans MS', 'Chalkboard SE', 'Marker Felt-Thin', sans-serif;
  position: relative;
  overflow: hidden;
}

/* Floating emojis background */
.birthday-gift-page::before {
  content: "🎂 🎈 🎉 🎊 🎁";
  position: absolute;
  font-size: 2rem;
  animation: float 6s infinite;
  opacity: 0.2;
  width: 100%;
  text-align: center;
  pointer-events: none;
}

/* Main container for the gift view */
.gift-container {
  max-width: 800px;
  width: 100%;
  background: linear-gradient(135deg, #fff 0%, #fff8e7 100%);
  border-radius: 20px;
  box-shadow: 
    0 10px 30px rgba(0, 0, 0, 0.1),
    0 0 0 2px rgba(255, 215, 0, 0.3);
  padding: 40px;
  text-align: center;
  position: relative;
  overflow: hidden;
  transform-style: preserve-3d;
  perspective: 1000px;
}

.gift-container::before {
  content: '🎈';
  position: absolute;
  top: 20px;
  left: 20px;
  font-size: 2rem;
  opacity: 0.5;
  transform: rotate(-20deg);
}

.gift-container::after {
  content: "🌟";
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 3rem;
  animation: spin 4s linear infinite;
}

.gift-container .header h1 {
  animation: rainbow 8s infinite;
  font-size: 3.5rem;
  text-shadow: 
    3px 3px 0 #ffd700,
    -3px -3px 0 #ff69b4;
  letter-spacing: 2px;
  margin: 0;
  color: #333;
}

/* Spinning cake emoji */
.gift-container .header h1::after {
  content: "🎂";
  display: inline-block;
  margin-left: 10px;
  animation: spin 4s linear infinite;
}

.gift-container .header p {
  font-size: 1.2rem;
  color: #555;
  margin: 20px 0;
  line-height: 1.6;
}

.video-link {
  margin: 20px 0;
}

.video-button {
  background: linear-gradient(45deg, #ffd700, #ff69b4);
  border: 3px dashed #fff;
  padding: 15px 30px;
  font-size: 1.3rem;
  cursor: pointer;
  border-radius: 12px;
  text-decoration: none;
  color: #333;
  display: inline-block;
  margin: 20px 0;
  transition: all 0.3s ease;
  box-shadow: 0 4px 15px rgba(255, 215, 0, 0.3);
  font-weight: bold;
  animation: float 3s infinite;
}

.video-button:hover {
  background: linear-gradient(45deg, #ff69b4, #ffd700);
  transform: scale(1.1) rotate(-3deg);
}

/* The nearly invisible grey button at bottom-right */
.original-toggle-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: linear-gradient(45deg, #ffd700, #ff69b4);
  border: 2px dashed #fff;
  padding: 10px 20px;
  font-size: 0.9rem;
  color: #666;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s;
  backdrop-filter: blur(5px);
  transform: rotate(-2deg);
}

.original-toggle-button:hover {
  transform: rotate(2deg) scale(1.1);
  background: linear-gradient(45deg, #ff69b4, #ffd700);
}

/* Container for the hidden original page */
.original-page {
  margin-top: 40px;
}

/* --- Original poetry game styles (adapted from the original CSS) --- */
.original-poetry-game .main-container {
  max-width: 800px;
  margin: 40px auto;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 30px;
  text-align: center;
}

.original-poetry-game .header {
  margin-bottom: 20px;
  text-align: center;
}

.original-poetry-game .header h1 {
  font-size: 2.8rem;
  margin: 0;
  color: #333;
  text-align: center;
}

.original-poetry-game .header p {
  font-size: 1.2rem;
  color: #555;
  margin-top: 5px;
  text-align: center;
}

.original-poetry-game .max-photo-container {
  margin: 20px auto;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
  border: 3px solid #ffd700;
}

.original-poetry-game .max-photo-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.original-poetry-game .error {
  color: red;
  margin: 10px 0;
}

.original-poetry-game .poem-input {
  margin-bottom: 20px;
}

.original-poetry-game .poem-input form {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 15px;
  position: relative;
  opacity: 1;
  transition: opacity 0.3s;
}

.original-poetry-game .poem-input form.loading {
  opacity: 0.7;
  pointer-events: none;
}

.original-poetry-game .poem-input label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 1rem;
  color: #333;
  text-align: left;
}

.original-poetry-game .poem-input input {
  width: 100%;
  padding: 8px 10px;
  font-size: 1rem;
  border: 2px solid #ccc;
  border-radius: 5px;
  margin-top: 5px;
  font-family: 'Comic Sans MS', 'Chalkboard SE', 'Marker Felt-Thin', sans-serif;
}

.original-poetry-game .poem-input button,
.original-poetry-game .toggle-poem {
  background: #ffd700;
  border: none;
  padding: 10px 15px;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
  font-family: 'Comic Sans MS', 'Chalkboard SE', 'Marker Felt-Thin', sans-serif;
  transition: background 0.3s;
  margin-top: 10px;
  position: relative;
  overflow: hidden;
}

.original-poetry-game .poem-input button:hover,
.original-poetry-game .toggle-poem:hover {
  background: #ffcf00;
}

.original-poetry-game .poem-input button:disabled,
.original-poetry-game .toggle-poem:disabled {
  background: #f0f0f0;
  cursor: not-allowed;
  color: #666;
}

.original-poetry-game .poem-input button.loading::after,
.original-poetry-game .toggle-poem.loading::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  width: 30%;
  background-color: #ffd700;
  animation: loading 1s infinite ease-in-out;
}

@keyframes loading {
  0% {
    left: -30%;
  }
  100% {
    left: 100%;
  }
}

.original-poetry-game .loading-indicator {
  text-align: center;
  padding: 20px;
  color: #666;
  font-style: italic;
  animation: pulse 1.5s infinite ease-in-out;
}

@keyframes pulse {
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.6;
  }
}

.original-poetry-game .poem-input input:disabled {
  background-color: #f5f5f5;
  border-color: #ddd;
  color: #999;
}

.original-poetry-game .last-verse {
  margin-bottom: 20px;
  padding: 15px;
  background: #f8f8f8;
  border-radius: 8px;
  border: 1px solid #eee;
  text-align: left;
}

.original-poetry-game .last-verse.loading {
  text-align: center;
  color: #666;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.original-poetry-game .loading-text {
  font-style: italic;
}

.original-poetry-game .loading-dots {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 20px;
}

.original-poetry-game .loading-dots::after {
  content: '...';
  font-size: 20px;
  animation: dots 1.5s infinite;
  position: absolute;
  left: 0;
}

@keyframes dots {
  0%, 20% { content: '.'; }
  40% { content: '..'; }
  60%, 100% { content: '...'; }
}

.original-poetry-game .poem-display {
  text-align: left;
  margin-top: 20px;
}

.original-poetry-game .poem-display h2 {
  text-align: center;
  font-size: 1.8rem;
  margin-bottom: 15px;
}

.original-poetry-game .poem-display ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.original-poetry-game .poem-display li {
  border-bottom: 1px dashed #ccc;
  padding: 10px 0;
}

.original-poetry-game .poem-display li:last-child {
  border-bottom: none;
}

.original-poetry-game .author {
  text-align: right;
  font-size: 0.9rem;
  color: #555;
}

.original-poetry-game .footer {
  margin-top: 30px;
  font-size: 0.8rem;
  color: #888;
}

.original-poetry-game .toast {
  position: fixed;
  top: 20px;
  right: 20px;
  background: #ffd700;
  color: #333;
  padding: 10px 15px;
  border-radius: 5px;
  box-shadow: 0 2px 6px rgba(0,0,0,0.2);
  font-family: 'Comic Sans MS', 'Chalkboard SE', 'Marker Felt-Thin', sans-serif;
}

.original-poetry-game .idea-button {
  background: none;
  border: 2px solid #ffd700;
  color: #333;
  padding: 8px 15px;
  border-radius: 5px;
  font-family: 'Comic Sans MS', 'Chalkboard SE', 'Marker Felt-Thin', sans-serif;
  cursor: pointer;
  margin-top: 10px;
  transition: all 0.2s;
}

.original-poetry-game .idea-button:hover {
  background: #ffd700;
  color: #000;
}

.original-poetry-game .find-lines-button {
  background: white;
  border: 2px solid #ffd700;
  color: #333;
  padding: 10px 20px;
  border-radius: 8px;
  font-family: inherit;
  cursor: pointer;
  font-size: 1rem;
  transition: all 0.2s;
}

.original-poetry-game .find-lines-button:hover {
  background: #fff8d6;
}

.original-poetry-game .author-verses {
  margin-top: 30px;
  padding: 20px;
  background: #f8f8f8;
  border-radius: 8px;
}

.original-poetry-game .author-verses h3 {
  margin: 0 0 15px;
  color: #333;
}

.original-poetry-game .author-verses .verse {
  margin-bottom: 15px;
  padding: 15px;
  background: white;
  border-radius: 6px;
}

.original-poetry-game .author-verses .verse p {
  margin: 5px 0;
  color: #333;
}

.gift-container .max-photo-container {
  margin: 30px auto;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  overflow: hidden;
  border: 4px solid #ffd700;
  box-shadow: 0 0 0 4px rgba(255, 215, 0, 0.2);
  transition: transform 0.3s ease;
  position: relative;
}

.gift-container .max-photo-container:hover {
  transform: scale(1.02);
}

/* Party hat */
.gift-container .max-photo-container::before {
  content: "🎩";
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 3rem;
  z-index: 2;
}

/* Confetti elements */
.confetti-piece {
  position: absolute;
  width: 10px;
  height: 10px;
  background: #ffd700;
  top: -10px;
  animation: confetti 3s ease-in infinite;
}

.confetti-piece:nth-child(2n) {
  background: #ff69b4;
  animation-delay: 0.2s;
  left: 20%;
}

.confetti-piece:nth-child(3n) {
  background: #00ff00;
  animation-delay: 0.4s;
  left: 40%;
}

.confetti-piece:nth-child(4n) {
  background: #ff0000;
  animation-delay: 0.6s;
  left: 60%;
}

/* Для оригинальной страницы оставляем прежние стили */
.birthday-gift-page .original-poetry-game {
  width: 100%;
  min-height: 100vh;
  padding: 20px;
}

@keyframes float {
  0%, 100% { transform: translateY(0) rotate(0); }
  50% { transform: translateY(-20px) rotate(5deg); }
}

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

@keyframes rainbow {
  0% { color: #ff0000; }
  20% { color: #ffa500; }
  40% { color: #ffff00; }
  60% { color: #008000; }
  80% { color: #0000ff; }
  100% { color: #ff0000; }
}

@keyframes confetti {
  0% { transform: translateY(0) rotate(0); opacity: 1; }
  100% { transform: translateY(100vh) rotate(720deg); opacity: 0; }
}

/* Add confetti container to gift-container in JSX */
.confetti-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}

/* Add these media queries at the end of your existing CSS file */

@media (max-width: 768px) {
  .birthday-gift-page {
    padding: 10px;
    display: block; /* Remove flex centering on mobile */
  }

  .gift-container {
    padding: 20px;
    margin-top: 20px;
  }

  .gift-container .header h1 {
    font-size: 2rem;
    text-shadow: 2px 2px 0 #ffd700, -2px -2px 0 #ff69b4;
  }

  .gift-container .header p {
    font-size: 1rem;
    padding: 0 10px;
  }

  .gift-container .max-photo-container {
    width: 150px;
    height: 150px;
  }

  .video-button {
    width: 90%;
    max-width: 300px;
    font-size: 1.1rem;
    padding: 12px 15px;
    white-space: normal;
  }

  .original-toggle-button {
    position: static;
    display: block;
    margin: 15px auto;
    font-size: 0.8rem;
    padding: 8px 15px;
    background: transparent;
    border: 1px solid rgba(255, 215, 0, 0.5);
    transform: none;
  }

  .original-toggle-button:hover {
    transform: none;
  }

  /* Reduce animation intensity on mobile */
  .gift-container .header h1,
  .video-button {
    animation-duration: 12s; /* Slower animations */
  }
}

/* Extra small devices */
@media (max-width: 320px) {
  .gift-container .header h1 {
    font-size: 1.7rem;
  }

  .video-button {
    font-size: 1rem;
    padding: 10px;
  }
}

/* Confetti animation */
@keyframes confettiRain {
  0% { transform: translateY(-100vh) rotate(0deg); }
  100% { transform: translateY(100vh) rotate(360deg); }
}

.confetti {
  position: fixed;
  width: 10px;
  height: 10px;
  pointer-events: none;
  animation: confettiRain linear infinite;
}

/* Different confetti colors and shapes */
.confetti:nth-child(4n) {
  background: #ffd700;
  width: 8px;
  height: 8px;
  animation-duration: 3s;
}

.confetti:nth-child(4n+1) {
  background: #ff69b4;
  width: 12px;
  height: 12px;
  animation-duration: 4s;
}

.confetti:nth-child(4n+2) {
  background: #7fff00;
  width: 6px;
  height: 15px;
  animation-duration: 5s;
}

.confetti:nth-child(4n+3) {
  background: #00bfff;
  width: 15px;
  height: 6px;
  animation-duration: 6s;
}

/* Random starting positions */
.confetti:nth-child(5n) { left: 5%; }
.confetti:nth-child(5n+1) { left: 25%; }
.confetti:nth-child(5n+2) { left: 45%; }
.confetti:nth-child(5n+3) { left: 65%; }
.confetti:nth-child(5n+4) { left: 85%; }

/* Add this to your React component */
.confetti-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 1000;
}

@media (max-width: 768px) {
  .confetti {
    /* Reduce number of confetti pieces on mobile for better performance */
    display: none;
  }
  .confetti:nth-child(3n) {
    /* Show only every third confetti piece on mobile */
    display: block;
  }
}
