.author-dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 20px;
}

.author-dialog {
  background: white;
  padding: 30px;
  border-radius: 12px;
  max-width: 500px;
  width: 90%;
  font-family: 'Comic Sans MS', 'Chalkboard SE', 'Marker Felt-Thin', sans-serif;
}

.author-dialog h2 {
  text-align: center;
  margin-bottom: 25px;
  font-size: 1.6rem;
  color: #333;
}

.authors-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 60vh;
  overflow-y: auto;
  padding: 10px;
}

.author-button {
  padding: 12px 20px;
  font-size: 1.1rem;
  background: #f8f8f8;
  border: 1px solid #eee;
  border-radius: 8px;
  cursor: pointer;
  font-family: 'Comic Sans MS', 'Chalkboard SE', 'Marker Felt-Thin', sans-serif;
  text-align: left;
  transition: all 0.2s;
}

.author-button:hover {
  background: #f0f0f0;
  border-color: #ddd;
}

.confirm-screen {
  text-align: center;
}

.selected-author {
  font-size: 1.4rem;
  margin: 20px 0;
  padding: 15px;
  background: #f8f8f8;
  border-radius: 8px;
}

.confirm-buttons {
  display: flex;
  gap: 15px;
  justify-content: center;
  margin-top: 25px;
}

.confirm-buttons button {
  padding: 12px 25px;
  font-size: 1.1rem;
  background: #ffd700;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-family: 'Comic Sans MS', 'Chalkboard SE', 'Marker Felt-Thin', sans-serif;
}

.confirm-buttons button:hover {
  background: #ffcf00;
}

.cancel-button {
  display: block;
  margin: 20px auto 0;
  padding: 8px 15px;
  font-size: 0.9rem;
  background: none;
  border: none;
  color: #666;
  cursor: pointer;
  text-decoration: underline;
}

.verses-screen {
  text-align: left;
}

.verses-screen h2 {
  text-align: center;
  margin-bottom: 20px;
}

.verses-list {
  margin: 20px 0;
}

.verses-list .verse {
  background: #f8f8f8;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 15px;
}

.verses-list .verse-pair {
  border-bottom: 1px dashed #ddd;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.verses-list .verse-pair:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

.verses-list .previous-line {
  color: #666;
  font-size: 0.9rem;
  font-style: italic;
  margin-bottom: 8px;
}

.verses-list .verse p {
  margin: 5px 0;
  color: #333;
  font-size: 1.1rem;
  line-height: 1.4;
}

.dialog-buttons {
  display: flex;
  gap: 15px;
  justify-content: center;
  margin-top: 25px;
}

.dialog-buttons button {
  padding: 12px 25px;
  font-size: 1.1rem;
  background: #ffd700;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-family: 'Comic Sans MS', 'Chalkboard SE', 'Marker Felt-Thin', sans-serif;
} 