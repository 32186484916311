.calendar-container {
  max-width: 1200px;
  margin: 0 auto;
}

.calendar-day {
  min-height: 100px;
}

@media (max-width: 768px) {
  .calendar-day {
    min-height: 60px;
  }
} 